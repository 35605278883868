import { Component, HostBinding, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location, DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

import { HeaderSettings } from './state/global/global.model';
import { GlobalService } from './state/global/global.service';
import { SessionQuery } from './state/session/session.query';
import { SessionService } from './state/session/session.service';
import { GlobalQuery } from './state/global/global.query';
import { fade } from './_core/utils/animations.utils';

import { environment } from '../environments/environment';

import { SelectDialogComponent } from './shared/components/select-dialog/select-dialog.component';
import { ORG_SETTINGS } from './state/session/session.store';
import { FilterService } from './state/entities/filter/filter.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [fade('fade', 500)]
})
export class AppComponent {
	public loaded = false;
	public headerSettings$: Observable<HeaderSettings>;
	public showSidebar = false;

	// Detect keypresses for setting admin mode
	@HostListener('document:keydown', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		if (event.key === ';' && event.ctrlKey) {
			this.globalService.setAdminMode();
		}
	}

	constructor(
		private readonly globalQuery: GlobalQuery,
		private readonly globalService: GlobalService,
		private readonly sessionService: SessionService,
		private readonly sessionQuery: SessionQuery,
		private readonly filterService: FilterService,
		private readonly router: Router,
		private readonly location: Location,
		private readonly dialog: MatDialog,
		@Inject(DOCUMENT) private readonly document: Document
	) {
		this.headerSettings$ = this.globalQuery.select('header');
		this.initializeApp()
			.catch(err => {
				console.log(err);
			});
	}

	private async initializeApp() {
		const settings = await this.loadOrgSettings()
			.catch(err => {
				console.log(err);
				localStorage.removeItem(ORG_SETTINGS);
				return null;
			});

		if(!settings) {
			console.error('Configuration error. Unable to load or parse API_MAP.');
		}

		// Once logged in, load settings and start up services
		this.sessionQuery.isLoggedIn$.pipe(filter(isLoggedIn => isLoggedIn === true)).subscribe(() => {

			this.loadGlobalSettings();
			this.startIntercom();
		});

		// Refresh our token before we do anything else
		if (this.location.path().indexOf('login') === -1) {
			this.sessionService.getUserStatus(this.sessionQuery.getToken()).subscribe(
				() => {},
				() => {
					// Save the location path so we can go back to it
					this.sessionService.setInitialUrl(this.location.path());

					// Invalid Access Token
					this.router.navigate(['login'], {
						replaceUrl: true,
						skipLocationChange: true
					});
					this.loaded = true;
				}
			);
		} else {
			this.loaded = true;
		}

		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
			this.showSidebar = val.url !== '/login';
		});

	}

	loadGlobalSettings() {
		this.globalService.get().subscribe(
			settings => {
				console.log('Loaded Global Settings', settings);
				Sentry.setTag('organizationName', settings.name);

				// Set the favicon
				let favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;
				favIcon.href = settings.settings.favIcon || 'assets/favicon/vmlyr.ico';

				// Set custom styles
				// this.customStyles = settings.customStyles;
				if (settings.settings.customStyles) {
					const style = document.createElement('style');
					style.textContent = settings.settings.customStyles;
					document.head.appendChild(style);
				}

				// Load filter options in before we start setting up the views
				this.filterService.setFilterOptionsFromSettings(settings);

				this.loaded = true;
			},
			(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
		);
	}

	startIntercom() {
		const user = this.sessionQuery.getProfile();

		window['Intercom']('boot', {
			app_id: 'k8uv4y5p',
			email: user.email,
			user_id: user.email,
			name: `${user.privateProfile?.nameFirst} ${user.privateProfile?.nameLast}`
		});
	}

	private async loadOrgSettings() {
		return new Promise((resolve, reject) => {
			if(!environment.exclusive) {
				const savedOrg = localStorage.getItem(ORG_SETTINGS);

				if(savedOrg) {
					try {
						let orgData = JSON.parse(savedOrg);
						environment.apiUrl = orgData.endpoint;
						environment.organizationId = orgData.organizationId;
						environment.calendarLicense = orgData.calendarLicense || environment.calendarLicense;
						environment.production = orgData.production || environment.production;
						environment.disableActivation = orgData.disableActivation || environment.disableActivation;
						environment.disablePlanning = orgData.disablePlanning || environment.disablePlanning;
						environment.currencySymbol = orgData.currencySymbol || environment.currencySymbol;
						environment.currencyCode = orgData.currencyCode || environment.currencyCode;
						environment.locale = orgData.locale || environment.locale;
						resolve(true);
					} catch(err) {
						reject(`Couldn't parse org settings.`);
						return;
					}
				} else {
					const dialogRef = this.dialog.open(
						SelectDialogComponent,
						{
							data: {
								title: 'Select an Organization',
								options: environment.apiSettings?.reduce((acc, cur) => {
									acc[cur.name] = cur;
									return acc;
								}, {})
							}
						}
					);

					dialogRef.afterClosed()
						.subscribe(result => {
							localStorage.setItem(ORG_SETTINGS, JSON.stringify(result));
							environment.apiUrl = result.endpoint;
							environment.organizationId = result.organizationId;
							environment.calendarLicense = result.calendarLicense || environment.calendarLicense;
							environment.production = result.production || environment.production;
							environment.disableActivation = result.disableActivation || environment.disableActivation;
							environment.disablePlanning = result.disablePlanning || environment.disablePlanning;
							environment.currencySymbol = result.currencySymbol || environment.currencySymbol;
							environment.currencyCode = result.currencyCode || environment.currencyCode;
							environment.locale = result.locale || environment.locale;
							resolve(true);
						});
				}
			} else {
				resolve(true);
			}
		});
	}
}
