import QueryFragmentGenerator from '../../_core/interfaces/query-fragment-generator.class';

import { SortStrategy } from '../../find/models/find.models';

export class WhereOptions {
	programAlias?: string;
}

export enum OrderKey {
	Name = 'name'
}

export class BrandInitiativeFragmentGenerator extends QueryFragmentGenerator {
	private aliasDefault: string = 'bi2';
	private optionsDefault: WhereOptions = {
		programAlias: 'p'
	};

	constructor() { super(); }

	public getFrom(
		whereOptions: WhereOptions,
		alias: string = this.aliasDefault
	) {
		whereOptions = { ...this.optionsDefault, ...whereOptions };

		const fragment = `
			, LATERAL (
				SELECT COALESCE (
					(
						SELECT
							JSON_BUILD_OBJECT (
								'name', ${alias}2."name"
							)
						FROM
							"brandInitiatives" AS ${alias}2
						WHERE
							${this.getWhere(whereOptions, alias)}
					),
					JSON_BUILD_OBJECT (
						'name', NULL
					)
				) AS "initiatives"
			) AS ${alias}
		`;

		return fragment;
	}

	public getGroupBy(orderKey: OrderKey, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."initiatives" ->> '${orderKey}'`;
	}

	public getOrderBy(orderKey: OrderKey, strategy: SortStrategy, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."initiatives" ->> '${orderKey}' ${strategy}`;
	}

	private getWhere(
		whereOptions: WhereOptions,
		alias: string
	) {
		const where = `
			${alias}2.id = ${whereOptions.programAlias}."brandInitiativeId"
		`;

		return where;
	}
}