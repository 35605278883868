import { ProgramSelect, ProgramSubSelects, Query as ProgramQuery } from '../../program/utils/query.utils';
import { Query as ExternalIdQuery } from '../../external-id/utils/query.utils';
import { BudgetAllocationSelect, Query as BudgetAllocationQuery } from '../../budget-allocation/utils/query.utils';
import { Query as FileQuery } from '../../file/utils/query.utils';
import { TacticSelect } from '../../tactic/utils/query.utils';

export enum PlanSelect {
	BudgetAllocations = 'budgetAllocations',
	BudgetCache = 'budgetCache',
	BrandCaches = 'brandCaches',
	BudgetPeriod = 'budgetPeriod',
	Retailer = 'retailer',
	Agency = 'agency',
	Location = 'location',
	Brands = 'brands',
	ProgramSector = 'programSector',
	ExternalIds = 'externalIds',
	Programs = 'programs',
	Tactics = 'tactics',
	Notes = 'notes',
	Tags = 'tags',
	Author = 'author',
	Owners = 'owners',
	Files = 'files',
	FeaturedPrograms = 'featuredPrograms'
}

export class PlanSubSelects {
	budgetAllocationSelects?: BudgetAllocationSelect[]
	programSelects?: ProgramSelect[]
	programTacticSelects?: TacticSelect[]
}

export class Query {
	private static readonly PLAN_QUERY_ALIAS: string = 'pl';

	public static getSelects(
		alias: string = this.PLAN_QUERY_ALIAS,
		targets: PlanSelect[] = Object.values(PlanSelect),
		asJsonSelect: boolean = false,
		excludes: PlanSelect[] = []
	) {
		if(!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${(json) ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${(json) ? `'name', ${alias}.name` : `${alias}.name`}
		`);
		selects.push(`
			${(json) ? `'detail', ${alias}.detail` : `${alias}.detail`}
		`);
		selects.push(`
			${(json) ? `'budgetPeriodId', ${alias}."budgetPeriodId"` : `${alias}."budgetPeriodId"`}
		`);
		selects.push(`
			${(json) ? `'status', ${alias}.status` : `${alias}.status`}
		`);
		selects.push(`
			${(json) ? `'retailerId', ${alias}."retailerId"` : `${alias}."retailerId"`}
		`);
		selects.push(`
			${(json) ? `'agencyId', ${alias}."agencyId"` : `${alias}."agencyId"`}
		`);
		selects.push(`
			${(json) ? `'locationId', ${alias}."locationId"` : `${alias}."locationId"`}
		`);
		selects.push(`
			${(json) ? `'programIds', ${alias}_programplans."programIds"` : `${alias}_programplans."programIds"`}
		`);
		selects.push(`
			${(json) ? `'brandStrategy', ${alias}."brandStrategy"` : `${alias}."brandStrategy"`}
		`);
		selects.push(`
			${(json) ? `'customerStrategy', ${alias}."customerStrategy"` : `${alias}."customerStrategy"`}
		`);
		selects.push(`
			${(json) ? `'programSectorId', ${alias}."programSectorId"` : `${alias}."programSectorId"`}
		`);
		selects.push(`
			${(json) ? `'commercePlatformWorkflow', ${alias}."commercePlatformWorkflow"` : `${alias}."commercePlatformWorkflow"`}
		`);
		selects.push(`
			${(json) ? `'authorId', ${alias}."authorId"` : `${alias}."authorId"`}
		`);
		selects.push(`
			${(json) ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${(json) ? `'deleted', ${alias}.deleted` : `${alias}.deleted`}
		`);

		if(targets.includes(PlanSelect.BudgetAllocations) && !excludes.includes(PlanSelect.BudgetAllocations)) {
			if(json) {
				selects.push(`
					'budgetAllocations', ${alias}_budgetallocations."budgetAllocationsArr"
				`);
			} else {
				selects.push(`
					${alias}_budgetallocations."budgetAllocationsArr" AS "budgetAllocations"
				`);
			}
		}

		if(targets.includes(PlanSelect.BudgetCache) && !excludes.includes(PlanSelect.BudgetCache)) {
			if(json) {
				selects.push(`
					'budgetCache', ${alias}_budgetcache."budgetCache"
				`);
			} else {
				selects.push(`
					${alias}_budgetcache."budgetCache" AS "budgetCache"
				`);
			}
		}

		if(targets.includes(PlanSelect.BrandCaches) && !excludes.includes(PlanSelect.BrandCaches)) {
			if(json) {
				selects.push(`
					'brandCaches', ${alias}_brandcaches."brandCaches"
				`);
			} else {
				selects.push(`
					${alias}_brandcaches."brandCaches" AS "brandCaches"
				`);
			}
		}

		if(targets.includes(PlanSelect.BudgetPeriod) && !excludes.includes(PlanSelect.BudgetPeriod)) {
			if(json) {
				selects.push(`
					'budgetPeriod', ${alias}_budgetperiod."budgetPeriod"
				`);
			} else {
				selects.push(`
					${alias}_budgetperiod."budgetPeriod" AS "budgetPeriod"
				`);
			}
		}

		if(targets.includes(PlanSelect.Retailer) && !excludes.includes(PlanSelect.Retailer)) {
			if(json) {
				selects.push(`
					'retailer', ${alias}_retailer."retailer" AS "retailer"
				`);
			} else {
				selects.push(`
					${alias}_retailer."retailer" AS "retailer"
				`);
			}
		}

		if(targets.includes(PlanSelect.Agency) && !excludes.includes(PlanSelect.Agency)) {
			if(json) {
				selects.push(`
					'agency', ${alias}_agency."agency" AS "agency"
				`);
			} else {
				selects.push(`
					${alias}_agency."agency" AS "agency"
				`);
			}
		}

		if(targets.includes(PlanSelect.Location) && !excludes.includes(PlanSelect.Location)) {
			if(json) {
				selects.push(`
					'location', ${alias}_location."location"
				`);
			} else {
				selects.push(`
					${alias}_location."location" AS "location"
				`);
			}
		}

		if(targets.includes(PlanSelect.Brands) && !excludes.includes(PlanSelect.Brands)) {
			if(json) {
				selects.push(`
					'brands', ${alias}_brands."brandsArr"
				`);
			} else {
				selects.push(`
					${alias}_brands."brandsArr" AS "brands"
				`);
			}
		}

		if(targets.includes(PlanSelect.ExternalIds) && !excludes.includes(PlanSelect.ExternalIds)) {
			if(json) {
				selects.push(`
					'externalIds', ${alias}_externalids."externalIdsArr"
				`);
			} else {
				selects.push(`
					${alias}_externalids."externalIdsArr" AS "externalIds"
				`);
			}
		}

		if(targets.includes(PlanSelect.ProgramSector) && !excludes.includes(PlanSelect.ProgramSector)) {
			if(json) {
				selects.push(`
					'programSector', ${alias}_programsector."programSector"
				`);
			} else {
				selects.push(`
					${alias}_programsector."programSector" AS "programSector"
				`);
			}
		}

		if(
			(targets.includes(PlanSelect.Programs) && !excludes.includes(PlanSelect.Programs)) ||
			(targets.includes(PlanSelect.Tactics) && !excludes.includes(PlanSelect.Tactics))
		) {
			if(json) {
				selects.push(`
					'programs', ${alias}_programs."programsArr"
				`);
			} else {
				selects.push(`
					${alias}_programs."programsArr" AS "programs"
				`);
			}
		}

		if(targets.includes(PlanSelect.Notes) && !excludes.includes(PlanSelect.Notes)) {
			if(json) {
				selects.push(`
					'notes', ${alias}_notes."notesArr"
				`);
			} else {
				selects.push(`
					${alias}_notes."notesArr" AS "notes"
				`);
			}
		}

		if(targets.includes(PlanSelect.Tags) && !excludes.includes(PlanSelect.Tags)) {
			if(json) {
				selects.push(`
					'tags', ${alias}_tags."tagsArr"
				`);
			} else {
				selects.push(`
					${alias}_tags."tagsArr" AS "tags"
				`);
			}
		}

		if(targets.includes(PlanSelect.Author) && !excludes.includes(PlanSelect.Author)) {
			if(json) {
				selects.push(`
					'author', ${alias}_author."author"
				`);
			} else {
				selects.push(`
					${alias}_author."author" AS "author"
				`);
			}
		}

		if(targets.includes(PlanSelect.Owners) && !excludes.includes(PlanSelect.Owners)) {
			if(json) {
				selects.push(`
					'owners', ${alias}_owners."ownersArr"
				`);
			} else {
				selects.push(`
					${alias}_owners."ownersArr" AS "owners"
				`);
			}
		}

		if(targets.includes(PlanSelect.Files) && !excludes.includes(PlanSelect.Files)) {
			if(json) {
				selects.push(`
					'files', ${alias}_files."filesArr"
				`);
			} else {
				selects.push(`
					${alias}_files."filesArr" AS files
				`);
			}
		}

		if(targets.includes(PlanSelect.FeaturedPrograms) && !excludes.includes(PlanSelect.FeaturedPrograms)) {
			if(json) {
				selects.push(`
					'featuredPrograms', ${alias}_featuredprograms."featuredprogramsArr"
				`);
			} else {
				selects.push(`
					${alias}_featuredprograms."featuredProgramsArr" AS "featuredPrograms"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.PLAN_QUERY_ALIAS,
		targets: PlanSelect[] = Object.values(PlanSelect),
		subSelects?: PlanSubSelects,
		excludes: PlanSelect[] = []
	) {
		const subQueries: string[] = [];

		subQueries.push(`
			LATERAL (
				SELECT ARRAY (
					SELECT
						prpl2."programsId" AS id
					FROM
						"programPlans" AS prpl2
					WHERE
						prpl2."plansId" = ${alias}.id
				) AS "programIds"
			) AS ${alias}_programplans
		`);

		if(!targets?.length) {
			return ',\n' + subQueries.join(',');
		}

		if(targets.includes(PlanSelect.BudgetAllocations)) {
			const budgetAllocationAlias = 'ba2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${BudgetAllocationQuery.getSelects(budgetAllocationAlias, subSelects?.budgetAllocationSelects ?? undefined, true)}
							)
						FROM
							"budgetAllocations" AS ${budgetAllocationAlias}
							${BudgetAllocationQuery.getSubqueries(budgetAllocationAlias, subSelects?.budgetAllocationSelects ?? undefined)}
						WHERE
							${budgetAllocationAlias}.deleted != true
							AND ${budgetAllocationAlias}."planId" = ${alias}.id
							AND ${budgetAllocationAlias}."programId" IS NULL
					) AS "budgetAllocationsArr"
				) AS ${alias}_budgetallocations
			`);
		}

		if(targets.includes(PlanSelect.BudgetCache)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'amountEstimated', SUM(bc2."amountEstimated"),
								'amountPlanned', SUM(bc2."amountPlanned"),
								'amountActual', SUM(bc2."amountActual"),
								'spendEstimated', SUM(bc2."spendEstimated"),
								'spendActual', SUM(bc2."spendActual")
							)
						FROM
							"budgetCaches" AS bc2
						WHERE
							bc2."planId" = ${alias}.id
							AND bc2."programId" IS NULL
							AND bc2."brandId" IS NULL
							AND bc2."tacticId" IS NULL
					) AS "budgetCache"
				) AS ${alias}_budgetcache
			`);
		}

		if(targets.includes(PlanSelect.BrandCaches)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							COALESCE(
								JSON_AGG(
									JSON_BUILD_OBJECT (
										'details', JSON_BUILD_OBJECT (
											'brandId', bc2."brandId"
										),
										'amountEstimated', bc2."amountEstimated",
										'amountPlanned', bc2."amountPlanned",
										'amountActual', bc2."amountActual",
										'spendEstimated', bc2."spendEstimated",
										'spendActual', bc2."spendActual"
									)
								), '[]'
							)
						FROM
							"budgetCaches" AS bc2
						WHERE
							bc2."planId" = ${alias}.id
							AND bc2."programId" IS NULL
							AND bc2."brandId" IS NOT NULL
							AND bc2."tacticId" IS NULL
					) AS "brandCaches"
				) AS ${alias}_brandcaches
			`);
		}

		if(targets.includes(PlanSelect.BudgetPeriod)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', bp2.id,
								'name', bp2.name,
								'organizationId', bp2."organizationId",
								'start', bp2.start,
								'end', bp2.end,
								'state', bp2.state,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								)
							)
						FROM
							"budgetPeriods" AS bp2
						LEFT JOIN
							users AS u2
							ON
								u2.id = bp2."authorId"
						WHERE
							bp2.id = ${alias}."budgetPeriodId"
					) AS "budgetPeriod"
				) AS ${alias}_budgetperiod
			`);
		}

		if(targets.includes(PlanSelect.Retailer)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', r2.id,
								'name', r2.name
							)
						FROM
							retailers AS r2
						WHERE
							r2.id = ${alias}."retailerId"
					) AS "retailer"
				) AS ${alias}_retailer
			`);
		}

		if(targets.includes(PlanSelect.Agency)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', a2.id,
								'name', a2.name
							)
						FROM
							agencies AS a2
						WHERE
							a2.id = ${alias}."agencyId"
					) AS "agency"
				) AS ${alias}_agency
			`);
		}

		if(targets.includes(PlanSelect.Location) && !excludes.includes(PlanSelect.Location)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', l2.id,
								'name', l2.name,
								'type', l2.type,
								'map', l2.map,
								'parent', COALESCE(ROW_TO_JSON(lparent), NULL)
							)
						FROM
							locations AS l2
						LEFT JOIN
							locations AS lparent
							ON
								NLEVEL(l2."map") > 1
								AND
								lparent."map" = SUBPATH(l2."map", 0, -1)
						WHERE
							l2.id = ${alias}."locationId"
					) AS "location"
				) AS ${alias}_location
			`);
		}

		if(targets.includes(PlanSelect.Brands)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', b2.id,
								'name', b2.name
							)
						FROM
							"planBrands" AS pb2
						LEFT JOIN
							brands AS b2
							ON
								b2.id = pb2."brandsId"
						WHERE
							pb2."plansId" = ${alias}.id
					) AS "brandsArr"
				) AS ${alias}_brands
			`);
		}

		if(targets.includes(PlanSelect.ExternalIds)) {
			const externalIdAlias: string = 'eid';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${ExternalIdQuery.getSelects('eid', undefined, true)}
							)
						FROM
							"externalIds" AS ${externalIdAlias}
						${ExternalIdQuery.getSubqueries('eid')}
						WHERE
							${externalIdAlias}."planId" = ${alias}.id
					) AS "externalIdsArr"
				) AS ${alias}_externalids
			`);
		}

		if(targets.includes(PlanSelect.ProgramSector) && !excludes.includes(PlanSelect.ProgramSector)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', ps2.id,
								'name', ps2.name
							)
						FROM
							"programSectors" AS ps2
						WHERE
							ps2.id = ${alias}."programSectorId"
					) AS "programSector"
				) AS ${alias}_programsector
			`);
		}

		if(targets.includes(PlanSelect.Programs) || targets.includes(PlanSelect.Tactics)) {
			const programAlias = 'plp2';

			let programSubSelects: ProgramSubSelects;
			if(subSelects?.programTacticSelects) {
				programSubSelects = {
					tacticSelects: subSelects.programTacticSelects
				};
			}

			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSONB_BUILD_OBJECT (
								${ProgramQuery.getSelects(programAlias, subSelects?.programSelects ?? null, true)}
							)
						FROM
							"programs" AS ${programAlias}
						INNER JOIN
							"programPlans" AS pppl2
							ON
								pppl2."programsId" = ${programAlias}.id
							${ProgramQuery.getSubqueries(programAlias, subSelects?.programSelects ?? null, programSubSelects ?? null)}
						WHERE
							pppl2."plansId" = ${alias}.id
							AND ${programAlias}.deleted != true
					) AS "programsArr"
				) AS ${alias}_programs
			`);
		}

		if(targets.includes(PlanSelect.Notes)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', n2.id,
								'layoutLocationCode', n2."layoutLocationCode",
								'body', n2.body,
								'author', JSON_BUILD_OBJECT (
									'id', u2.id,
									'email', u2."email",
									'profile', u2."profile"
								),
								'created', n2.created
							)
						FROM
							"planNotes" AS pn2
						LEFT JOIN
							notes AS n2
							ON
								n2.id = pn2."notesId"
						LEFT JOIN
							users AS u2
							ON
								n2."authorId" = u2.id
						WHERE
							pn2."plansId" = ${alias}.id
					) AS "notesArr"
				) AS ${alias}_notes
			`);
		}

		if(targets.includes(PlanSelect.Tags)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', tags.id,
								'name', tags.name
							)
						FROM
							"planTags" AS ptags2
						LEFT JOIN
							tags
							ON
								tags.id = ptags2."tagsId"
						WHERE
							ptags2."plansId" = ${alias}.id
					) AS "tagsArr"
				) AS ${alias}_tags
			`);
		}

		if(targets.includes(PlanSelect.Author)) {
			subQueries.push(`
				LATERAL (
					SELECT
						JSON_BUILD_OBJECT (
							'id', u2.id,
							'email', u2."email",
							'profile', u2."profile"
						) AS "author"
					FROM
						"users" AS u2
					WHERE
						u2.id = ${alias}."authorId"
				) AS ${alias}_author
			`);
		}

		if(targets.includes(PlanSelect.Owners)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', users.id,
								'email', users.email,
								'profile', users.profile
							)
						FROM
							"planOwners" AS po2
						LEFT JOIN
							users
							ON
							users.id = po2."usersId"
						WHERE
							po2."plansId" = ${alias}.id
					) AS "ownersArr"
				) AS ${alias}_owners
			`);
		}

		if(targets.includes(PlanSelect.Files) && !excludes.includes(PlanSelect.Files)) {
			const fileAlias: string = 'f';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${FileQuery.getSelects(fileAlias, null, true)}
							)
						FROM
							"files" AS ${fileAlias}
						WHERE
							${fileAlias}."planId" = ${alias}.id
					) AS "filesArr"
				) AS ${alias}_files
			`);
		}

		if(targets.includes(PlanSelect.FeaturedPrograms) && !excludes.includes(PlanSelect.FeaturedPrograms)) {
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								'id', fp.id,
								'plansId', fp."plansId",
								'programsId', fp."programsId",
								'sortIdx', fp."sortIdx"
							)
						FROM
							"plansFeaturedPrograms" AS fp
						WHERE
							fp."plansId" = ${alias}.id
					) as "featuredProgramsArr"
				) AS ${alias}_featuredprograms
			`);
		}

		if(!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
