import {
	Entity,
	PrimaryGeneratedColumn,
	Column,
	Index
} from 'typeorm';

import { Sortable } from '../_core/decorators/sortable.decorator';

import { BudgetCache, PublicBudgetCache } from '../budget-cache/budget-cache.entity';

export type PublicRetailer = Pick<Retailer, 'id' | 'name' | 'color'> & {
	budgetCache?: PublicBudgetCache
};

@Entity('retailers')
@Index(['remoteId'], { unique: true })
export class Retailer {
	constructor(value?: Partial<Retailer>) {
		for(let k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	@Sortable
	name: string;

	budgetCache?: BudgetCache;

	@Column('text', { nullable: true })
	color?: string;

	public toPublic(): PublicRetailer {
		let pub: PublicRetailer = {
			id: this.id,
			name: this.name,
			color: this.color,
		};

		if(this.budgetCache) {
			pub.budgetCache = new BudgetCache(this.budgetCache).toPublic();
		}

		return pub;
	}
}