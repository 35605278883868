import QueryFragmentGenerator from '../../_core/interfaces/query-fragment-generator.class';

import { SortStrategy } from '../../find/models/find.models';

export class WhereOptions {
	planAlias?: string;
	programAlias?: string;
	planMatch?: boolean;
	programMatch?: boolean;
}

export enum OrderKey {
	Name = 'name'
}

export class RetailerFragmentGenerator extends QueryFragmentGenerator {
	private aliasDefault: string = 'r2';
	private optionsDefault: WhereOptions = {
		planAlias: 'pl',
		programAlias: 'p'
	};

	constructor(whereOptions?: WhereOptions) {
		super();

		this.optionsDefault = { ...this.optionsDefault, ...whereOptions };
	}

	public getFrom(
		whereOptions: WhereOptions,
		alias: string = this.aliasDefault
	) {
		whereOptions = { ...this.optionsDefault, ...whereOptions };

		const fragment = `
			, LATERAL (
				SELECT (
					SELECT
						JSON_BUILD_OBJECT (
							'name', ${alias}2."name"
						)
					FROM
						"retailers" AS ${alias}2
					WHERE
						${this.getWhere(whereOptions, alias)}
				) AS "retailerValues"
			) AS ${alias}
		`;

		return fragment;
	}

	public getGroupBy(orderKey: OrderKey, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `${alias}."retailerValues" ->> '${orderKey}'`;
	}

	public getOrderBy(orderKey: OrderKey, strategy: SortStrategy, alias: string = this.aliasDefault) {
		if(!Object.values(OrderKey).includes(orderKey)) {
			orderKey = Object.values(OrderKey)[0];
		}
		return `(${alias}."retailerValues" ->> '${orderKey}') ${strategy}`;
	}

	private getWhere(
		whereOptions: WhereOptions,
		alias: string
	) {
		let where = '';

		if(whereOptions.planMatch) {
			where += `${alias}2.id = ${whereOptions.planAlias}."retailerId"`;
		} else if(whereOptions.programMatch) {
			where += `${alias}2.id = ${whereOptions.programAlias}."retailerId"`;
		}

		return where;
	}
}