import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/browser';

if (environment.production) {
	enableProdMode();

	Sentry.init({
		release: `shopper-planning-web@${window['version']}`,
		environment: window['environment'] ?? 'development',
		dsn: 'https://2305387dae7e4e379dd32c9cb66decfd@o506945.ingest.sentry.io/5597323',
		autoSessionTracking: true,
		integrations: [
			new BrowserTracing({
				tracingOrigins: ['localhost', environment.apiUrl], // TODO: Add real server urls
				routingInstrumentation: Sentry.routingInstrumentation
			}),
			new Sentry.Replay()
		],

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0
	});

	Sentry.setTag('organization', environment.organizationId);
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
