<div
	*ngIf="showPlatformPanelSidebar$ | async"
	[ngClass]="{
		'collapsed-menu': !collapsedMenuShown,
		'collapsed-menu-shown': collapsedMenuShown
	}"
	class="sidebar-nav"
>
	<div class="logo pl-5 pr-5 pt-6 pb-6">
		<ng-container *ngIf="settings$ | async as settings">
			<a class="main-logo">
				<img [src]="settings.settings.logo || '/assets/vmly-r-logo-white.svg'" alt="VMLY&R Commerce" />
			</a>
			<a class="favicon-logo">
				<img [src]="settings.settings.logoSquare || '/assets/vmly-r-favicon-white.png'" />
			</a>
		</ng-container>
	</div>
	<nav>
		<ul class="flex-1">
			<li>
				<a
					*ngIf="!disableHome"
					class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
					navigation-click
					[routerLink]="['/home']"
					[class.active]="(routerActiveState$ | async)?.url.search('/home') > -1"
					ngxTippy
					data-tippy-content="Home"
					[tippyProps]="{ placement: 'right' }"
				>
					<i class="uil icon-16 uil-home"></i>
					<span class="page-name pl-3">Home</span>
				</a>

				<ng-container *ngIf="!onlyShowDashboard">
					<!-- Plan -->
					<a
						*ngIf="!disablePlanning"
						class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
						navigation-click
						[routerLink]="['/planning']"
						[class.active]="(routerActiveState$ | async)?.url.search('/planning') > -1"
						ngxTippy
						data-tippy-content="Plan"
						[tippyProps]="{ placement: 'right' }"
					>
						<i class="uil icon-16 uil-navigator"></i>
						<span class="page-name pl-3">Plan</span>
					</a>

					<!-- Activate -->
					<a
						*ngIf="!disableActivation"
						class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
						navigation-click
						[routerLink]="['/activate']"
						[class.active]="(routerActiveState$ | async)?.url.search('/activate') > -1"
						ngxTippy
						data-tippy-content="Activate"
						[tippyProps]="{ placement: 'right' }"
					>
						<i class="uil icon-16 uil-bolt-alt"></i>
						<span class="page-name pl-3">Activate</span>
					</a>

					<!-- Media Plan -->
					<a
						*ngIf="showMediaPlanSection"
						class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
						navigation-click
						[routerLink]="['/media-planning']"
						[class.active]="(routerActiveState$ | async)?.url.search('/media-planning') > -1"
						ngxTippy
						data-tippy-content="Media Plan"
						[tippyProps]="{ placement: 'right' }"
					>
						<i class="uil icon-16 uil-chart-growth-alt"></i>
						<span class="page-name pl-3">Media Plan</span>
					</a>

					<!-- Reporting -->
					<a
						*ngIf="!disableReporting"
						class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
						navigation-click
						[routerLink]="['/reporting']"
						[class.active]="(routerActiveState$ | async)?.url.search('/reporting') > -1"
						ngxTippy
						data-tippy-content="Reporting"
						[tippyProps]="{ placement: 'right' }"
					>
						<i class="uil icon-16 uil-chart-pie"></i>
						<span class="page-name pl-3">Reporting</span>
					</a>

					<!-- File Review -->
					<a
						*ngIf="fileReviewsEnabled && showFileReviewPage"
						class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
						navigation-click
						[routerLink]="['/file-review']"
						[class.active]="(routerActiveState$ | async)?.url.search('/file-review') > -1"
						ngxTippy
						data-tippy-content="File Review"
						[tippyProps]="{ placement: 'right' }"
					>
						<i class="uil icon-16 uil-file-search-alt"></i>
						<span class="page-name pl-3">File Review</span>
					</a>

					<!-- Admin Page -->
					<ng-container *ngIf="profile$ | async as profile">
						<a
							*ngIf="profile.role === 'admin' || profile.role === 'super-admin'"
							class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
							navigation-click
							[routerLink]="['/admin']"
							[class.active]="(routerActiveState$ | async)?.url.search('/admin') > -1"
							ngxTippy
							data-tippy-content="Admin Settings"
							[tippyProps]="{ placement: 'right' }"
						>
							<i class="uil icon-16 uil-cog"></i>
							<span class="page-name pl-3">Admin Settings</span>
						</a>
					</ng-container>

					<!-- Settings -->
					<ng-container *ngIf="profile$ | async as profile">
						<a
							*ngIf="profile.role === UserRole.User"
							class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
							navigation-click
							[routerLink]="['/settings']"
							[class.active]="(routerActiveState$ | async)?.url.search('/settings') > -1"
							ngxTippy
							data-tippy-content="Settings"
							[tippyProps]="{ placement: 'right' }"
						>
							<i class="uil icon-16 uil-cog"></i>
							<span class="page-name pl-3">Settings</span>
						</a>
					</ng-container>
				</ng-container>
			</li>
		</ul>

		<ul>
			<li>
				<a
					class="pl-5 pr-5 pt-4 pb-4 display-flex align-items-center"
					navigation-click
					(click)="logout()"
					ngxTippy
					data-tippy-content="Logout"
					[tippyProps]="{ placement: 'right' }"
				>
					<i class="uil icon-16 uil-signout"></i>
					<span class="page-name pl-3">Logout</span>
				</a>
			</li>
		</ul>
	</nav>

	<!-- <div class="bottom-container">
		<div (click)="toggleMenu()" class="collapse-menu flex align-items-center border-top">
			<i class="uil uil-arrow-circle-left"></i>
			<span class="label pl-3">Collapse menu</span>
		</div>
	</div> -->
</div>
