import { Tactic } from '../tactic/tactic.model';
import { v4 as uuidv4 } from 'uuid';
import { ExternalId } from '../external-id/external-id.model';
import { User } from '../user/user.model';
import { Vendor } from '../vendor/vendor.model';
import { ColumnCollection } from '../../table/table.model';
import { defaultStyleOptions } from '../../global/chart.model';
import { Filter, FilterGroupType } from '../filter/filter.model';
import { Brand } from '../brand/brand.model';
import { InvoiceSelect } from '../../../../../../api/src/invoice/utils/query.utils';
import { BudgetCache } from '../../global/global.model';

/**
 * Invoice Model
 * Invoices are entities that keep track of final amounts paid out to vendors for a tactic.
 * Invoices are children of Tactics.
 * Invoices are tracked with Costs and Budget Allocations to compare the estimated spends with the invoice actual amount.
 */
export interface Invoice {
	id: string;
	number: string;
	amount: number;
	status: InvoiceStatus;
	tacticId?: Tactic['id'];
	tactic?: Tactic;
	externalIds: ExternalId[];
	brandAllocations: any[];
	dueDate: string;
	proMax: string;
	vendor: Vendor;
	authorId?: User['id'];
	author: User;
	created: string;
	type: string;
	budgetCache?: BudgetCache;
}

/**
 * Invoices have set a set 'status' to represent at what stage of the payment process the invoice is in.
 */
export type InvoiceStatus = 'Sent' | 'Received - Unpaid' | 'Received - Paid';
export const InvoiceStatuses: InvoiceStatus[] = ['Sent', 'Received - Unpaid', 'Received - Paid'];

/**
 * Return a particular color for the invoice status.
 *
 */
// TODO: Need to find a way to make this work closer to how other entities are colorized in the settings object.
// With this setup, it could be very easy to have colors change accidentally based on the array length of the colors.
export function getInvoiceStatusColor(status: InvoiceStatus) {
	switch (status) {
		case 'Sent':
			return defaultStyleOptions.colors[4];

		case 'Received - Unpaid':
			return defaultStyleOptions.colors[3];

		case 'Received - Paid':
			return defaultStyleOptions.colors[1];
	}
}

/**
 * Create a blank invoice. Default property values are set here.
 */
export function createInvoice(params: Partial<Invoice>) {
	return {
		id: uuidv4(),
		number: '',
		amount: undefined,
		status: 'Sent',
		...params
	} as Invoice;
}

/**
 * The columns that are unique to invoices.
 */
export const InvoiceColumnCollection: ColumnCollection = {
	id: 'invoices',
	name: 'Invoices',
	items: [
		{
			id: 'invoice-tactic',
			name: 'Tactic',
			path: 'tactic',
			exportPath: 'tactic.name',
			type: 'entityName',
			category: 'Invoice',
			entityTypes: ['invoice'],
			dependencies: [InvoiceSelect.Tactic]
		},
		{
			id: 'invoice-status',
			name: 'Status',
			path: 'status',
			type: 'badge',
			category: 'Invoice',
			entityTypes: ['invoice'],
			editable: {
				enabled: true,
				type: 'single-select',
				options: InvoiceStatuses.map(status => ({ id: status, name: status }))
			}
		},
		{
			id: 'invoice-amount',
			name: 'Invoice Amount',
			path: 'amount',
			type: 'currency',
			category: 'Invoice',
			entityTypes: ['invoice'],
			editable: {
				enabled: true,
				type: 'currency'
			}
		},
		{
			id: 'invoice-due-date',
			name: 'Due Date',
			path: 'dueDate',
			type: 'date',
			category: 'Invoice',
			entityTypes: ['invoice'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'End Date is not editable.'
			}
		},
		{
			id: 'invoice-vendor',
			name: 'Vendor Name',
			path: 'vendor',
			exportPath: 'vendor.name',
			type: 'entityName',
			category: 'Invoice',
			entityTypes: ['invoice'],
			dependencies: [InvoiceSelect.Vendor]
		},
		{
			id: 'invoice-number',
			name: 'Invoice Number',
			path: 'number',
			type: 'field',
			category: 'Invoice',
			entityTypes: ['invoice'],
			editable: {
				enabled: true,
				type: 'text',
				dismissWithUpdatedEntity: true
			}
		}
	]
};

/**
 * The default columns that should be active on any table showing invoices.
 */
export const InvoiceDefaultActiveColumns = [
	'Name',
	'Tactic',
	'Vendor Name',
	'Actual Spend',
	'Status',
	'Invoice Amount',
	'Due Date',
	'shim',
	'action'
];

/**
 * The filters that are unique to invoices.
 */
export const InvoiceFilterCollection: Filter[] = [
	{
		id: 'invoice-group-by',
		name: 'Grouped By',
		category: 'overall',
		matchEndpoint: 'invoices',
		slug: 'groups',
		type: 'single-select',
		order: 2,
		options: [
			{
				id: undefined,
				name: '(None)',
				value: undefined
			},
			{
				id: 'invoiceStatus',
				name: 'Invoice Status',
				entityName: 'Status',
				value: 'statuses'
			},
			{
				id: 'retailers',
				name: 'Retailer',
				entityName: 'Retailer',
				value: 'retailers'
			},
			{
				id: 'brands',
				name: 'Brand',
				entityName: 'Brand',
				value: 'brands'
			},
			{
				id: 'invoiceVendor',
				name: 'Vendor',
				entityName: 'Vendor',
				value: 'vendors'
			},
			{
				id: 'program',
				name: 'Program',
				entityName: 'Program',
				value: 'programs'
			},
			{
				id: 'tactic',
				name: 'Tactic',
				entityName: 'Tactic',
				value: 'tactics'
			}
		],
		extra: {
			buttonSelect: {
				buttonText: 'Group By ${formGroup.groups.name}',
				emptyText: 'Group By'
			},
			iconName: 'uil-layer-group'
		}
	},
	{
		id: 'invoice-number',
		name: 'Invoice Number',
		category: 'overall',
		matchEndpoint: 'invoices',
		slug: 'number',
		type: 'search',
		order: 2
	},
	{
		id: 'invoice-status',
		name: 'Invoice Status',
		category: 'invoices',
		matchEndpoint: 'invoices',
		slug: 'status',
		type: 'single-select',
		options: [{ id: undefined, name: '(None)' }, ...InvoiceStatuses.map(status => ({ id: status, name: status }))]
	},
	{
		id: 'invoice-vendors',
		name: 'Vendors',
		category: 'invoices',
		matchEndpoint: 'invoices',
		slug: 'vendors',
		type: 'multi-select',
		extra: {
			suggestEntity: 'vendor'
		}
	},
	{
		id: 'invoice-past-due',
		name: 'Is Past Due',
		category: 'invoices',
		matchEndpoint: 'invoices',
		slug: 'pastDue',
		type: 'toggle'
	},
	{
		id: 'invoice-created-by-me',
		name: 'Created by Me',
		category: 'invoices',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'invoices',
		slug: 'createdByMe',
		type: 'toggle'
	}
];
