import { Condition, LogicalConjunction } from '../../../../../../api/src/_core/models/math-operations';
import { BudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';
import { Filter as FilterCondition } from '../../../../../../api/src/export/dtos/export.dto';
import { KimberlyClarkProgramStatusEnum } from '../../../../../../api/src/import/adapters/kimberly-clark/kimberly-clark.model';
import { ValueType } from '../../global/global.model';
import { AppSection } from '../../../../../../api/src/quick-view/quick-view.entity';

export enum FilterGroupType {
	displayGroup = 'displayGroup',
	plan = 'plan',
	program = 'program',
	plannedProgram = 'plannedProgram',
	tactic = 'tactic',
	additional = 'additional'
}

export const FilterGroupTypeDisplay: { [key in FilterGroupType]: string } = {
	[FilterGroupType.displayGroup]: 'Display Settings',
	[FilterGroupType.plan]: 'Plan Level',
	[FilterGroupType.program]: 'Program Level',
	[FilterGroupType.plannedProgram]: 'Planned Program Level',
	[FilterGroupType.tactic]: 'Tactic Level',
	[FilterGroupType.additional]: 'Additional Filters'
};

export const FilterGroupTypeAppSection: { [key in AppSection]: FilterGroupType[] } = {
	[AppSection.Planning]: [FilterGroupType.displayGroup, FilterGroupType.plan, FilterGroupType.plannedProgram, FilterGroupType.additional],
	[AppSection.Activation]: [FilterGroupType.displayGroup, FilterGroupType.program, FilterGroupType.tactic, FilterGroupType.additional],
	[AppSection.MediaPlan]: [FilterGroupType.displayGroup, FilterGroupType.program, FilterGroupType.tactic, FilterGroupType.additional]
};

/**
 * Filter Model
 * A filter is an item that represents a user defined selection that should limit returned results for a view.
 * These filter items are mainly used today on the overview pages (Plan / Activate) to limit the results of each of the 'Views' in those pages.
 * However, this model can be used for any filter item in the system.
 *
 * Filters are currently created inside of the `*.model.ts` files for entities that show up in the overview pages.
 */
export interface Filter {
	id: string;
	name: string;
	category: string;
	slug: string;
	type: string;
	groupTypes?: FilterGroupType[];
	valueType?: ValueType; // Describes the valueType to use for any values of the filter field.
	options?: FilterOption[];
	value?: FilterOption | FilterOption[];
	entityName?: string; // Used if you need to map back to the entity types coming from API
	matchEndpoint?: string; // Used to make sure this filter category matches the main entity that is loaded in.
	order?: number;
	visibilityCondition?: FilterCondition;
	visibilityConditions?: { operator: LogicalConjunction; filterConditions: FilterCondition[] }; // Multiple conditions that must be met for the filter to be visible
	extra?: {
		maskPath?: string;
		iconName?: string; // Name of a UIL icon from Metron.  Only used in some cases.
		optionsFromSettings?: {
			path: string;
			condition?: FilterCondition; // Condition value can accept ${} merge tags and reference { form, option } variables.
		};
		buttonSelect?: {
			selectLayout?: 'singles' | 'multiples' | 'groups';
			buttonText?: string;
			emptyText?: string; // Text to show if no value is selected
			iconOnly?: boolean;
			showExpandButton?: boolean;
			buttonColor?: string;
			buttonSize?: string;
			iconName?: string;
			entityValue?: string;
			classes?: string;
			applyButton?: boolean;
			maxButtonTextOptions?: number; // The maximum options to but in the button text for multiples (defaults to 2)
		};
		[key: string]: any;
	};
}

/**
 * Create a blank filter.  Insert any default values here.
 */
export function createFilter(params: Partial<Filter>) {
	return {
		...params
	} as Filter;
}

/**
 * A filter option is a value that represents a certain kind of data for that filter.
 * Usually used as options for a select component or autocomplete for chips inputs.
 */
export interface FilterOption {
	id?: string;
	name: string;
	value?: any;
	entityName?: string; // Used if you need to map back to the entity types coming from API
	visibilityCondition?: FilterCondition;
	budgetPeriod?: BudgetPeriod;
	extra?: {
		maskPath?: string;
		affectedProperties?: string[];
		[key: string]: any;
	};
}

/**
 * Configuration of what filters should be included or excluded from a list of filters.
 */
export interface FilterListConfig {
	dynamic?: boolean; // If true, the component should shift available filters based on the current entity type.
	includeEndpoint?: string;
	includeIds?: string[];
	excludeIds?: string[];
}

/**
 * Configuration of what filters should be included or excluded from a list of filters for inline filters.
 */
export interface InlineFilterListConfig {
	left?: FilterListConfig;
	right?: FilterListConfig;
}

/**
 * An object of different filter properties along with their currently selected filter values
 */
export interface FilterParameters {
	[key: string]: any;
}

/**
 * Filters can be grouped into 'collections' to facilitate grouping them in the UI.
 */
export interface FilterCollection {
	name: string;
	filters: Filter[];
}

export interface IncludeOption extends FilterOption {
	value: {
		endpoint: string;
		include: string[];
	};
}

/**
 * Filter collections that apply to all entities in the 'overview' pages.
 */
export const OverallFilterCollection: Filter[] = [
	{
		id: 'overall-budget-period',
		name: 'Budget Period',
		category: 'overall',
		slug: 'budgetPeriod',
		type: 'single-select',
		order: 1,
		options: [],
		extra: {
			iconName: 'uil-calendar-alt',
			buttonSelect: {
				showExpandButton: true
			},
			hideFromActiveCounter: true
		}
	},
	{
		id: 'overall-budget-period-multiselect',
		name: 'Budget Period(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plan],
		slug: 'budgetPeriods',
		type: 'multi-select',
		order: 2,
		options: [],
		extra: {
			iconName: 'uil-calendar-alt',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Budget Period(s)'
			},
			hideFromActiveCounter: true
		}
	},
	{
		id: 'overall-retailers',
		name: 'Retailer(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plan],
		slug: 'retailers',
		type: 'multi-select',
		options: [],
		order: 3,
		visibilityCondition: {
			path: 'settings.entities.program.retailer.disabled',
			condition: Condition.NEQ,
			value: true
		},
		extra: {
			iconName: 'uil-store',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Retailers'
			},
			hideFromActiveCounter: true
		}
	},
	{
		id: 'overall-brands',
		name: 'Brand(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plan],
		slug: 'brands',
		type: 'multi-select',
		order: 4,
		options: [],
		extra: {
			iconName: 'uil-tag-alt',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Brands'
			},
			hideFromActiveCounter: true
		}
	},
	{
		id: 'overall-program-phase',
		name: 'Program Phase',
		category: 'overall',
		slug: 'programPhase',
		type: 'single-select',
		options: [],
		order: 5
	},

	{
		id: 'overall-program-phases',
		name: 'Program Phase(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program],
		slug: 'programPhases',
		type: 'multi-select',
		options: [],
		extra: {
			iconName: 'uil-apps',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Program Phases'
			},
			hideFromActiveCounter: true
		},
		order: 5
	},
	{
		id: 'overall-program-types',
		name: 'Program Type(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plannedProgram],
		slug: 'programTypes',
		type: 'multi-select',
		options: [],
		order: 6
	},
	{
		id: 'overall-program-sector',
		name: 'Program Sector',
		category: 'overall',
		slug: 'programSector',
		type: 'single-select',
		options: [],
		order: 7
	},
	{
		id: 'overall-program-sectors',
		name: 'Program Sector(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plan],
		slug: 'programSectors',
		type: 'multi-select',
		options: [],
		order: 8
	},
	{
		id: 'overall-agencies',
		name: 'Agencies',
		category: 'overall',
		groupTypes: [FilterGroupType.program],
		slug: 'agencies',
		type: 'multi-select',
		options: [],
		order: 8,
		visibilityCondition: {
			path: 'settings.entities.program.agency.disabled',
			condition: Condition.NEQ,
			value: true
		},
		extra: {
			iconName: 'uil-store',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Agencies'
			},
			hideFromActiveCounter: true
		}
	},
	{
		id: 'overall-start-date',
		name: 'Start Date',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plannedProgram],
		slug: 'start',
		type: 'date',
		options: [],
		order: 9
	},
	{
		id: 'overall-external-ids',
		name: 'External Ids',
		category: 'overall',
		groupTypes: [FilterGroupType.program],
		slug: 'externalIdQuery',
		type: 'search',
		order: 10
	},
	{
		id: 'overall-vendors',
		name: 'Vendor(s)',
		category: 'overall',
		slug: 'vendors',
		type: 'multi-select',
		order: 3,
		options: [],
		extra: {
			iconName: 'uil-tag-alt',
			buttonSelect: {
				showExpandButton: true,
				emptyText: 'Vendors'
			},
			hideFromActiveCounter: true
		}
	},

	{
		id: 'compare-to',
		name: 'Compare To',
		category: 'compare',
		slug: 'compareTo',
		type: 'single-select',
		options: [
			{
				id: 'none',
				name: undefined
			},
			{
				id: 'compare-to-period',
				name: 'Previous Period'
			},
			{
				id: 'year-over-year',
				name: 'Year Over Year'
			}
		],
		extra: {
			iconName: 'uil-comparison',
			buttonSelect: {
				emptyText: 'Compare'
			}
		}
	},
	{
		id: 'column-select',
		name: 'Columns',
		category: 'columns',
		matchEndpoint: 'overall',
		slug: 'columnSelect',
		type: 'multi-select',
		valueType: 'entityNames'
	},
	{
		id: 'overall-program-phase',
		name: 'Program Phase',
		category: 'programs',
		slug: 'programPhase',
		type: 'single-select',
		options: [],
		matchEndpoint: 'programs',
		order: 8
	},
	// {
	// 	id: 'overall-program-phases',
	// 	name: 'Program Phase(s)',
	// 	category: 'overall',
	// 	slug: 'programPhases',
	// 	type: 'multi-select',
	// 	options: [],
	// 	order: 8
	// },
	// {
	// 	id: 'overall-program-sector',
	// 	name: 'Program Sector',
	// 	category: 'overall',
	// 	slug: 'programSector',
	// 	type: 'single-select',
	// 	options: [],
	// 	order: 9
	// },
	// {
	// 	id: 'overall-program-sectors',
	// 	name: 'Program Sector(s)',
	// 	category: 'overall',
	// 	slug: 'programSectors',
	// 	type: 'multi-select',
	// 	options: [],
	// 	order: 10
	// },
	// {
	// 	id: 'overall-start-date',
	// 	name: 'Start Date',
	// 	category: 'overall',
	// 	slug: 'start',
	// 	type: 'date',
	// 	options: [],
	// 	order: 11
	// },
	{
		id: 'overall-brand-initiative',
		name: 'Brand Initiative(s)',
		category: 'overall',
		groupTypes: [FilterGroupType.program],
		slug: 'brandInitiatives',
		type: 'multi-select',
		options: [],
		matchEndpoint: 'programs',
		order: 12
	},
	{
		id: 'overall-program-tactic-types',
		category: 'overall',
		name: 'Tactic Type(s)',
		entityName: 'TacticType',
		slug: 'tacticTypes',
		type: 'multi-select',
		order: 13,
		options: []
	},
	{
		id: 'overall-reset',
		category: 'overall',
		name: 'Reset',
		slug: 'reset',
		type: 'button-reset',
		order: 99,
		options: []
	},
	{
		id: 'overall-program-types',
		name: 'Program Type(s)',
		category: 'overall',
		slug: 'programTypes',
		type: 'multi-select',
		options: [],
		order: 13
	},
	{
		id: 'overall-tactic-group-statuses',
		name: 'Tactic Group Statuses',
		category: undefined,
		slug: 'tacticGroupStatuses',
		type: 'multi-select',
		order: 14,
		options: []
	}
	// {
	// 	id: 'overall-program-types',
	// 	name: 'Program Type(s)',
	// 	category: 'overall',
	// 	slug: 'programTypes',
	// 	type: 'multi-select',
	// 	options: [],
	// 	order: 13
	// }
];

/**
 * Default filter collections for the 'Activate' overview page.
 */
export const ActivateFilterCollection = [
	{
		id: 'overall-activate-show-me',
		name: 'Show Me',
		category: 'overall',
		groupTypes: [FilterGroupType.displayGroup],
		slug: 'include',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'programs',
				name: 'Programs',
				value: {
					endpoint: 'programs',
					include: []
				}
			},
			{
				id: 'programs-tactics',
				name: 'Programs + Tactics',
				value: {
					endpoint: 'programs',
					include: ['tactics']
				}
			},
			{
				id: 'programs-tactics-invoices',
				name: 'Programs + Tactics + Invoices',
				value: {
					endpoint: 'programs',
					include: ['tactics', 'invoices']
				}
			},
			{
				id: 'tactics',
				name: 'Tactics',
				value: {
					endpoint: 'tactics',
					include: []
				}
			},
			{
				id: 'tactics-invoices',
				name: 'Tactics + Invoices',
				value: {
					endpoint: 'tactics',
					include: ['invoices']
				}
			},
			{
				id: 'invoices',
				name: 'Invoices',
				value: {
					endpoint: 'invoices',
					include: undefined
				}
			}
		],
		extra: {
			iconName: 'uil-comparison',
			buttonSelect: {
				emptyText: 'Compare'
			}
		}
	}
];

/**
 * Default filter collections for the 'Plan' overview page.
 */
export const PlanningFilterCollection = [
	{
		id: 'overall-planning-show-me',
		name: 'Show Me',
		category: 'overall',
		groupTypes: [FilterGroupType.displayGroup],
		slug: 'include',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'plans',
				name: 'Plans',
				value: {
					endpoint: 'plans',
					include: []
				}
			},
			{
				id: 'plans-programs',
				name: 'Plans + Planned Programs',
				value: {
					endpoint: 'plans',
					include: ['programs']
				}
			},
			{
				id: 'programs',
				name: 'Planned Programs',
				value: {
					endpoint: 'programs',
					include: []
				}
			}
		]
	}
];

/**
 * Default filter collections for the 'MediaPlan' overview page.
 */
export const MediaPlanningFilterCollection = [
	{
		id: 'overall-media-plan-show-me',
		name: 'Show Me',
		category: 'programs',
		groupTypes: [FilterGroupType.displayGroup],
		slug: 'include',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'programs',
				name: 'Media Plans',
				value: {
					endpoint: 'programs',
					include: ['tacticGroups']
				}
			},
			{
				id: 'programs-tactics',
				name: 'Media Plans + Tactics',
				value: {
					endpoint: 'programs',
					include: ['tactics', 'tacticGroups']
				}
			}
		],
		extra: {
			iconName: 'uil-comparison',
			buttonSelect: {
				emptyText: 'Compare'
			}
		}
	},
	{
		id: 'media-plan-group-by',
		name: 'Grouped By',
		category: 'programs',
		groupTypes: [FilterGroupType.displayGroup],
		matchEndpoint: 'programs',
		slug: 'groups',
		type: 'single-select',
		order: 1.5,
		options: [
			{
				id: 'none',
				name: '(None)',
				value: undefined
			},
			{
				id: 'retailers',
				name: 'Retailers',
				entityName: 'Retailer',
				value: 'retailers',
				visibilityCondition: {
					path: 'settings.entities.program.retailer.disabled',
					condition: Condition.NEQ,
					value: true
				}
			},
			{
				id: 'agencies',
				name: 'Agencies',
				entityName: 'Agency',
				value: 'agencies',
				visibilityCondition: {
					path: 'settings.entities.program.agency.disabled',
					condition: Condition.NEQ,
					value: true
				}
			},
			{
				id: 'brands',
				name: 'Brands',
				entityName: 'Brand',
				value: 'brands'
			},

			{
				id: 'locationRegion',
				name: 'Region',
				entityName: 'Location',
				value: 'locationRegion',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true
				}
			},
			{
				id: 'locationArea',
				name: 'Area',
				entityName: 'Location',
				value: 'locationArea',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true
				}
			},
			{
				id: 'locationCountry',
				name: 'Country',
				entityName: 'Location',
				value: 'locationCountry',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true
				}
			}
		],
		extra: {
			buttonSelect: {
				buttonText: 'Group By ${formGroup.groups.name}',
				emptyText: 'Group By'
			},
			iconName: 'uil-layer-group'
		}
	}
];

/**
 * Kimberly Clark Custom Filters
 */

export const KimberlyClarkFilterCollection = [
	{
		id: 'kimberly-clark-program-status',
		name: 'Program Status',
		category: 'kimberlyClark',
		slug: 'kimberlyClarkStatus',
		type: 'multi-select',
		options: [
			{
				id: KimberlyClarkProgramStatusEnum.PendingBrief,
				name: KimberlyClarkProgramStatusEnum.PendingBrief
			},
			{
				id: KimberlyClarkProgramStatusEnum.InProgress,
				name: KimberlyClarkProgramStatusEnum.InProgress
			},
			{
				id: KimberlyClarkProgramStatusEnum.Complete,
				name: KimberlyClarkProgramStatusEnum.Complete
			}
		],
		order: 11
	}
];
