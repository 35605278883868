import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export class CacheTarget {
	method: string;
	routeFragments: string[]
}

@Injectable({ providedIn: 'root' })
export class CacheService {



	// Clear after 10 minutes?



	private cache: Record<string, Record<string, HttpEvent<any>>> = {};
	private readonly cacheTargets: CacheTarget[] = [
		// { method: 'POST', routeFragments: [environment.apiUrl, '/find/'] }
	];
	private readonly poisonousTargets: CacheTarget[] = [
		{ method: 'PUT', routeFragments: [environment.apiUrl, '/program/', '/tactic/'] }
	];

	public get(method: string, url: string, hash: string) {
		const key = `${method}-${encodeURI(url)}`;
		if(this.cache?.[key]?.[hash]) {
			return this.cache[key][hash];
		}
		return;
	}

	public put(method: string, url: string, hash: string, value: HttpEvent<any>) {
		const key = `${method}-${encodeURI(url)}`;
		if(!this.cache[key]) {
			this.cache[key] = {};
		}
		this.cache[key][hash] = value;
	}

	public clear() {
		this.cache = {};
	}

	public canCache(method: string, url: string) {
		for(const group of this.cacheTargets) {
			let match = true;
			if(group.method === method) {
				for(const segment of group.routeFragments) {
					if(!url.includes(segment)) {
						match = false;
					}
				}
				if(match) {
					return true;
				}
			}
		}
		return false;
	}

	public isPoisonous(method: string, url: string) {
		for(const group of this.poisonousTargets) {
			let match = true;
			if(group.method === method) {
				for(const segment of group.routeFragments) {
					if(!url.includes(segment)) {
						match = false;
					}
				}
				if(match) {
					return true;
				}
			}
		}
		return false;
	}
}
