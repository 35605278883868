import { PublicBudgetPeriod } from '../../../../../api/src/budget-period/budget-period.entity';
import { formatDate } from '@angular/common';

export function getDateAsPostgresStyle(date: Date): string {
	return date
		.toISOString()
		.replace('T', ' ')
		.replace('Z', '');
}

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
	const _MS_PER_DAY = 1000 * 60 * 60 * 24;

	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function addDays(date, days) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate() + days,
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds()
	);
}

export function subtractDays(date, days) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate() - days,
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds()
	);
}

export function getDateRangeFromPreset(preset: string, relativeDate: Date, budgetPeriods?: PublicBudgetPeriod[]) {
	budgetPeriods = budgetPeriods?.filter(bp => !!bp) || [];
	switch (preset) {
		case 'full-period':
			if (budgetPeriods?.length) {
				const { earliest, latest } = findEarliestAndLatestDate(budgetPeriods);
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(earliest);
				const budgetPeriodEnd = new Date(latest);

				return {
					start: budgetPeriodStart,
					end: budgetPeriodEnd
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 0, 1),
					end: new Date(relativeDate.getFullYear(), 11, 31)
				};
			}

		case 'ytd': // Year to Date
			return {
				start: new Date(relativeDate.getFullYear(), 0, 1),
				end: relativeDate
			};

		case 'mtd': // Month to Date
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 1),
				end: relativeDate
			};

		case 'qtd': // Quarter to Date
			return {
				start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3, 1),
				end: relativeDate
			};

		case 'this-month': // This Month
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 1),
				end: new Date(relativeDate.getFullYear(), relativeDate.getMonth() + 1, 0)
			};

		case 'last-month': // Last Month
			return {
				start: new Date(relativeDate.getFullYear(), relativeDate.getMonth() - 1, 1),
				end: new Date(relativeDate.getFullYear(), relativeDate.getMonth(), 0)
			};

		case 'this-quarter': // This Quarter
			return {
				start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3, 1),
				end: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 3, 0)
			};

		case 'last-quarter': // Last Quarter
			return {
				start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 - 3, 1),
				end: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3, 0)
			};

		case 'next-quarter': // Next Quarter
			return {
				start: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 3, 1),
				end: new Date(relativeDate.getFullYear(), Math.floor(relativeDate.getMonth() / 3) * 3 + 6, 0)
			};

		case 'q1': // Get the first quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], 0);
				console.log('Budget quarter', foundCustomDefinedQuarter, budgetPeriods);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				// Get the first 3 months based on the start date of the budget period.

				return {
					start: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth(), 1),
					end: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 3, 0)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 0, 1),
					end: new Date(relativeDate.getFullYear(), 3, 0)
				};
			}

		case 'q2': // Get the second quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);
				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], 1);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				return {
					start: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 3, 1),
					end: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 6, 0)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 3, 1),
					end: new Date(relativeDate.getFullYear(), 6, 0)
				};
			}

		case 'q3': // Get the third quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);
				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], 2);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				return {
					start: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 6, 1),
					end: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 9, 0)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 6, 1),
					end: new Date(relativeDate.getFullYear(), 9, 0)
				};
			}

		case 'q4': // Get the fourth quarter of the budget period start and end dates (if they exist)
			if (budgetPeriods?.length === 1) {
				// Budget period start and end are strings, so convert them to dates.
				const budgetPeriodStart = new Date(budgetPeriods[0].start);
				const budgetPeriodEnd = new Date(budgetPeriods[0].end);

				const foundCustomDefinedQuarter = _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriods[0], 3);
				if (foundCustomDefinedQuarter) {
					return foundCustomDefinedQuarter;
				}

				return {
					start: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 9, 1),
					end: new Date(budgetPeriodStart.getFullYear(), budgetPeriodStart.getMonth() + 12, 0)
				};
			} else {
				return {
					start: new Date(relativeDate.getFullYear(), 9, 1),
					end: new Date(relativeDate.getFullYear(), 12, 0)
				};
			}
	}

	function _findCustomDefinedQuarterFromBudgetPeriod(budgetPeriod: PublicBudgetPeriod, quarterIndex: number) {
		if (!budgetPeriod?.quarters?.length) {
			return null;
		} else if (budgetPeriod?.quarters?.length < 3) {
			return null;
			// 	Check if any of quarters is null
		} else if (budgetPeriod.quarters.some(quarter => !quarter.start || !quarter.end)) {
			return null;
		}
		// Budget period start and end are strings, so convert them to dates.
		if (budgetPeriod?.quarters?.length > 0 && budgetPeriod.quarters[quarterIndex]) {
			const quarter = budgetPeriod.quarters[quarterIndex];
			return {
				start: new Date(createTimezoneProofDateString(quarter.start)),
				end: new Date(createTimezoneProofDateString(quarter.end))
			};
		}
	}
}

export function removeTimezonefromDate(date: Date) {
	return formatDate(new Date(date), `MMM d \''\'yy`, 'en-US');
}

export function convertDateWithoutTimezone(date: Date): Date {
	const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert timezone offset to milliseconds
	return new Date(date.getTime() - timezoneOffset);
}

export function createTimezoneProofDateString(date: Date | string) {
	if (!(date instanceof Date)) {
		const { year, month, day } = parseDateString(date);
		return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T12:00:00.000Z`;
	} else {
		date = convertDateWithoutTimezone(date);

		// Set the time to 12:00 UTC
		const utcYear = date.getUTCFullYear();
		const utcMonth = date.getUTCMonth();
		const utcDate = date.getUTCDate();

		// Note: Months are 0-indexed in JavaScript Date, hence the +1. Ensure to pad single digits with leading zeros.
		return `${utcYear}-${String(utcMonth + 1).padStart(2, '0')}-${String(utcDate).padStart(2, '0')}T12:00:00.000Z`;
	}
}
export function parseDateString(dateString: string) {
	// Regular expression to match the "YYYY-MM-DD" format
	const regex = /^\d{4}-\d{2}-\d{2}$/;

	if (!regex.test(dateString)) {
		throw new Error('Invalid date format. Please use "YYYY-MM-DD".');
	}

	const parts = dateString.split('-');
	const year = parseInt(parts[0], 10);
	const month = parseInt(parts[1], 10);
	const day = parseInt(parts[2], 10);
	return { year, month, day };
}

export function findEarliestAndLatestDate(dates: { start?; end? }[]): { earliest: Date; latest: Date } {
	// Flatten the array to get all dates, excluding undefined dates
	const allDates: Date[] = dates.reduce((acc: Date[], range: { start; end }) => {
		if (range.start) {
			acc.push(new Date(range.start));
		}
		if (range.end) {
			acc.push(new Date(range.end));
		}
		return acc;
	}, []);

	if (allDates.length === 0) {
		// If no valid dates were found, return null for both earliest and latest dates
		return { earliest: null, latest: null };
	}

	// Sort dates to get the earliest and latest
	allDates.sort((a, b) => a.getTime() - b.getTime());

	const earliest = allDates[0];
	const latest = allDates[allDates.length - 1];

	return { earliest, latest };
}
