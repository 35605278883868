import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { joinWithProp } from '../../../_core/utils/array.utils';
import { replaceMergeTags } from '../../../_core/utils/string.utils';
import { Filter, FilterParameters } from './filter.model';

export function getFormButtonCurrentValueText(filter: Filter, formGroup: FormGroup) {
	if (filter.extra?.buttonSelect?.iconOnly) {
		return undefined;
	}

	// If we already have text, use it and apply any merge tags from the form value
	if (filter.extra?.buttonSelect?.buttonText) {
		return replaceMergeTags(filter.extra?.buttonSelect.buttonText, { filter, formGroup: formGroup.value });
	}

	if (filter.extra?.buttonSelect?.selectLayout === 'multiples') {
		return (
			joinWithProp(formGroup.value[filter.slug], 'name', ', ', filter.extra?.buttonSelect?.maxButtonTextOptions || 2) ||
			filter.extra?.buttonSelect?.emptyText ||
			'Select ' + filter.name
		);
	} else if (filter.type === 'date-range') {
		// If we have a date range, format the dates and show them together
		// If we don't, use the default text.

		let start = formGroup.value.start;
		let end = formGroup.value.end;

		if (!start && !end) {
			return 'Select ' + filter.name;
		}

		if (start) {
			start = formatDate(start, 'MMM d, yyyy', environment.locale);
		}

		if (end) {
			end = formatDate(end, 'MMM d, yyyy', environment.locale);
		}

		return (start || '?') + ' - ' + (end || '?');
	} else {
		let name = filter.extra?.buttonSelect?.emptyText || 'Select ' + filter.name;

		if (formGroup.value[filter.slug] && formGroup.value[filter.slug].id) {
			name = formGroup.value[filter.slug].name;
		}

		return name;
	}
}

export function doesFilterMatchEndpoint(filter: Filter, endpoint: string) {
	let match = false;

	// Filter category should be 'overall' or have the same cat as one of the included entities
	if (filter.category === 'overall' || filter.category === endpoint) {
		match = true;
	}

	// If the filter has a matchEnpoint, it can only be true if it matches
	// console.log('filter.matchEndpoint', filter.matchEndpoint, endpoint);
	if (filter.matchEndpoint) {
		if (filter.matchEndpoint === endpoint) {
			match = true;
		} else {
			match = false;
		}
	}

	return match;
}

export function getFilterParametersBySlug(filters: Filter[], formGroup: FormGroup): FilterParameters {
	const filteredFormValue: FilterParameters = {};

	filters.forEach(filter => {
		if (formGroup.value[filter.slug] !== undefined) {
			filteredFormValue[filter.slug] = formGroup?.value?.[filter.slug];
		}
	});

	return filteredFormValue;
}
