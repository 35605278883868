import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index,
	JoinColumn,
	ManyToOne
} from 'typeorm';

import { MeasurementType, PublicMeasurementType } from '../measurement-type/measurement-type.entity';
import { Tactic } from '../tactic/tactic.entity';
import { PublicUser, User } from '../user/user.entity';

export type PublicMeasurement = Pick<Measurement,
	'id' | 'measurementTypeId' | 'tacticId' |
	'benchmark' | 'value' | 'created'
> & {
	measurementType: PublicMeasurementType,
	author: PublicUser
};

@Entity('measurements')
@Index(['measurementTypeId', 'tacticId'], { unique: true })
export class Measurement {
	constructor(value?: Partial<Measurement>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	measurementTypeId: string;
	@ManyToOne(
		() => MeasurementType,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	@JoinColumn({ name: 'measurementTypeId' })
	measurementType: MeasurementType | Partial<MeasurementType>;

	@Column('text', { nullable: false })
	tacticId: string;
	@ManyToOne(
		() => Tactic,
		{
			//eager: true,
			onDelete: 'CASCADE',
			orphanedRowAction: 'delete'
		}
	)
	@JoinColumn({ name: 'tacticId' })
	tactic: Tactic | Partial<Tactic>;

	@Column('numeric', { nullable: true })
	benchmark?: number;

	@Column('numeric', { nullable: true })
	value?: number;

	@Column('text', { nullable: false })
	authorId: string;
	@ManyToOne(
		type => User,
		{
			eager: true,
			onDelete: 'CASCADE'
		}
	)
	author: User;

	@Column({ type: 'timestamptz', nullable: false, default: () => 'NOW()' })
	created: string;

	public toPublic(): PublicMeasurement {
		const pub: Partial<PublicMeasurement> = {
			id: this.id,
			measurementTypeId: this.measurementTypeId,
			tacticId: this.tacticId,
			created: this.created
		};

		if(this.measurementType) {
			pub.measurementType = new MeasurementType(this.measurementType).toPublic();
		}

		if(this.author) {
			pub.author = new User(this.author).toPublic();
		}

		if(typeof this.benchmark !== 'undefined') {
			pub.benchmark = Number(this.benchmark);
		}

		if(typeof this.value !== 'undefined') {
			pub.value = Number(this.value);
		}

		return pub as PublicMeasurement;
	}
}