import { BudgetCache, Retailer } from '../../global/global.model';
import { Entity } from '../entities.model';
import { v4 as uuidv4 } from 'uuid';
import { Program, Sector } from '../program/program.model';
import { Tag } from '../tag/tag.model';
import { Note } from '../note/note.model';
import { User } from '../user/user.model';
import { ColumnCollection } from '../../table/table.model';
import { defaultStyleOptions } from '../../global/chart.model';
import { BudgetAllocation } from '../budget-allocation/budget-allocation.model';
import { Filter, FilterGroupType } from '../filter/filter.model';
import { Brand, BrandStrategy } from '../brand/brand.model';
import { FeaturedProgram } from '../../../../../../api/src/plan/featured-program.entity';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';
import { Condition } from '../../../../../../api/src/_core/models/math-operations';
import { PlanSelect } from '../../../../../../api/src/plan/utils/query.utils';

/**
 * Plan model
 * A plan is an entity that represents a worksheet of budgets, goals, and programs that
 * a user wants to execute for an upcoming budget period.
 *
 * Plans have their own budgets, that really represent the money that is being provided to fund their plans
 * Plans have many programs, whose budgets are compared to the money being provided to illustrate where there are gaps in funding.
 * Plans are typically grouped and structured by brand / category, and sometimes retailer.
 * Plans have a loose workflow / approval process which will lock a plan from further editing.
 * Plans are eventually locked and migrated, and the programs are migrated into being 'active' to be managed in the Activate Pages.
 */
export interface Plan extends Entity {
	id: string;
	name: string;
	budgetAllocations: BudgetAllocation[];
	budgetPeriod: PublicBudgetPeriod;
	retailer: Retailer;
	brands: Brand[];
	status: PlanStatus;
	programs: Program[];
	programSector: Sector;
	tags: Tag[];
	notes: Note[];
	owners?: Partial<User>[];
	budgetCache: BudgetCache;
	author: User;
	customerStrategy: string;
	brandStrategy: string | BrandStrategy[];
	brandStrategyMerged?: {
		brandStrategy: BrandStrategy;
		brandStrategyFreeform: string;
	};
	featuredPrograms?: FeaturedProgram[];
}

/**
 * The API can provide data for a the programs associated with a plan, from the last budget period.
 * This is used to inform and compare the current plan budgets.
 */
export interface PreviousPeriodData {
	budgetPeriod: PublicBudgetPeriod;
	plans: Plan[];
	programs: Program[];
	allPrograms: Program[];
}

/**
 * Plans have status, which reflects where in the workflow they are.
 * 'draft' - The plan can be actively modified
 * 'approved' - The plan is locked and cannot be modified
 */
export type PlanStatus = 'draft' | 'approved';

/**
 * Create a blank plan.  Insert default values here.
 */
export function createPlan(params: Partial<Plan>) {
	return {
		id: uuidv4(),
		status: 'draft',
		...params
	} as Plan;
}

/**
 * Get the default color of a status
 */
// TODO: We need to move these colors into a similar setup as other settings endpoint entities.
export function getPlanStatusColor(status: PlanStatus) {
	switch (status) {
		case 'draft':
			return defaultStyleOptions.colors[2];

		case 'approved':
			return defaultStyleOptions.colors[1];
	}
}

/**
 * Plan Sections
 * @deprecated
 */
export interface PlanSection {
	label: string;
	slug: string;
}

/**
 * Default Plan Sections
 * @deprecated
 */
export const PlanSections: PlanSection[] = [
	{
		label: 'Plan Details',
		slug: 'details'
	},
	{
		label: 'Programs',
		slug: 'programs'
	},
	{
		label: 'Activity',
		slug: 'activity'
	}
];

/**
 * The unique table columns for plans
 */
export const PlanColumnCollection: ColumnCollection = {
	id: 'plans',
	name: 'Plans',
	items: [
		/* {
			id: 'plan-budget',
			name: 'Plan Budget',
			path: 'amountEstimated',
			exportPath: 'budgetCache.amountEstimated',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['plan'],
			extra: {
				showDifferenceWith: 'amountPlanned',
				invertDifference: false
			}
		}, */
		{
			id: 'program-budget',
			name: 'Program Budget',
			path: 'amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			hideFromMenu: true,
			editable: {
				type: 'program-budget',
				enabled: true,
				uneditableReason: 'Planned Program Budget is not editable.'
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning'
			}
		},
		{
			id: 'retailer',
			name: 'Retailer',
			path: 'retailer',
			exportPath: 'retailer.name',
			type: 'entityName',
			category: 'Plan',
			entityTypes: ['plan']
		},
		{
			id: 'owners',
			name: 'Owners',
			path: 'owners',
			type: 'users',
			category: 'Plan',
			entityTypes: ['plan'],
			editable: {
				type: 'multi-select',
				filterId: '${type}-owners',
				enabled: true,
				uneditableReason: 'Owners are not editable.'
			},
			dependencies: [PlanSelect.Owners]
		},
		{
			id: 'brand-strategy',
			name: 'Brand Strategy',
			path: 'brandStrategy',
			type: 'brandStrategy',
			category: 'Plan',
			entityTypes: ['plan'],
			extra: {
				property: 'name', // Property to pluck from
				reduceOperation: 'join' // 'join', 'count', 'sum', 'average', 'first'
			}
		},
		{
			id: 'plan-status',
			name: 'Plan Status',
			path: 'status',
			type: 'badge',
			category: 'Plan',
			entityTypes: ['plan']
		}
	]
};

/**
 * The default active columns that should be on a table when showing plans
 */
export const PlanDefaultActiveColumns = ['Name', 'Plan Status', 'Planned Program Budget', 'Brand(s)', 'Tags', 'Owners', 'shim', 'action'];

/**
 * The filters that are unique to plans
 */
export const PlanFilterCollection: Filter[] = [
	{
		id: 'plan-group-by',
		name: 'Grouped By',
		category: 'overall',
		groupTypes: [FilterGroupType.displayGroup],
		matchEndpoint: 'plans',
		slug: 'groups',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'none',
				name: '(None)',
				value: undefined
			},
			{
				id: 'retailers',
				name: 'Retailers',
				entityName: 'Retailer',
				value: 'retailers'
			},
			{
				id: 'brands',
				name: 'Brands',
				entityName: 'Brand',
				value: 'brands'
			},
			{
				id: 'planSectors',
				name: 'Sectors',
				entityName: 'ProgramSector',
				value: 'programSectors'
			}
			// {
			// 	id: 'macroObjectives',
			// 	name: 'Macro Objectives',
			// 	entityName: 'MacroObjectives',
			// 	value: 'macroObjectives'
			// }
		],
		extra: {
			buttonSelect: {
				buttonText: 'Group By ${formGroup.groups.name}',
				emptyText: 'Group By'
			},
			iconName: 'uil-layer-group'
		}
	},

	{
		id: 'plan-name',
		name: 'Name',
		category: 'overall',
		groupTypes: [FilterGroupType.plan],
		matchEndpoint: 'plans',
		slug: 'name',
		type: 'search',
		order: 5
	},
	{
		id: 'plan-sector',
		name: 'Sector',
		category: 'plans',
		groupTypes: [FilterGroupType.plan],
		slug: 'programSector',
		matchEndpoint: 'plans',
		type: 'single-select',
		options: []
	},
	{
		id: 'plan-status',
		name: 'Plan Status',
		category: 'plans',
		slug: 'workflowStatus',
		matchEndpoint: 'plans',
		type: 'single-select',
		options: [],
		order: 4
	},
	{
		id: 'plan-statuses',
		name: 'Plan Statuses',
		category: 'plans',
		groupTypes: [FilterGroupType.plan],
		slug: 'workflowStatuses',
		matchEndpoint: 'plans',
		type: 'multi-select',
		options: [],
		order: 4
	},
	{
		id: 'plan-owned-by-me',
		name: 'Owned by Me',
		category: 'overall',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'plans',
		slug: 'ownedByMe',
		type: 'toggle'
	},
	{
		id: 'plan-created-by-me',
		name: 'Created by Me',
		category: 'overall',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'plans',
		slug: 'createdByMe',
		type: 'toggle'
	},
	{
		id: 'plan-owners',
		name: 'Owners',
		category: 'plans',
		groupTypes: [FilterGroupType.plan],
		matchEndpoint: 'plans',
		slug: 'owners',
		type: 'multi-select',
		extra: {
			suggestEntity: 'user'
		}
	},
	{
		id: 'plan-created-by',
		name: 'Created By',
		category: 'plans',
		slug: 'authors',
		type: 'multi-select',
		extra: {
			suggestEntity: 'user'
		}
	},
	{
		id: 'plan-tags',
		name: 'Tags',
		category: 'plans',
		groupTypes: [FilterGroupType.plan],
		slug: 'tags',
		type: 'multi-select',
		extra: {
			suggestEntity: 'tag-program'
		}
	},
	{
		id: 'plan-program-name',
		name: 'Plan Program Name',
		category: 'plans',
		groupTypes: [FilterGroupType.plannedProgram],
		matchEndpoint: 'plans',
		slug: 'programName',
		type: 'search',
		order: 5
	},
	{
		id: 'plan-program-start-date',
		name: 'Planned Program Start Date',
		category: 'plans',
		groupTypes: [FilterGroupType.plannedProgram],
		slug: 'programStart',
		type: 'date',
		options: [],
		order: 9
	},
	{
		id: 'plan-program-at-risk',
		name: 'At Risk',
		category: 'plans',
		groupTypes: [FilterGroupType.additional],
		slug: 'programAtRisk',
		type: 'toggle'
	}
];
