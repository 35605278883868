import {
	Column,
	Entity,
	PrimaryGeneratedColumn,
	Index
} from 'typeorm';
import { AggregateFunction } from '../_core/models/math-operations';

export type PublicMeasurementType = Pick<MeasurementType, 'id' | 'name' | 'unit' | 'aggregations'>;

export enum MeasurementUnit {
	Float = 'float',
	Integer = 'integer',
	Currency = 'currency'
}

export class Aggregation {
	slug: string;
	label: string;
	hideLabel?: boolean;
	path: string;
	reduceOperation?: AggregateFunction;
}

export class Aggregations {
	primary: Aggregation;
	compare?: Aggregation[];
}

@Entity('measurementTypes')
@Index(['remoteId'], { unique: true })
export class MeasurementType {
	constructor(value?: Partial<MeasurementType>) {
		if(value) {
			value = JSON.parse(JSON.stringify(value));
		}
		for(const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	id: string;

	@Column('text', { nullable: false })
	remoteId: string;

	@Column('text', { nullable: false })
	name: string;

	@Column({
		type: 'enum',
		enum: MeasurementUnit,
		default: MeasurementUnit.Float
	})
	unit: MeasurementUnit;

	@Column('jsonb', { nullable: true })
	aggregations?: Aggregations;

	public toPublic(): PublicMeasurementType {
		return {
			id: this.id,
			name: this.name,
			unit: this.unit,
			aggregations: this.aggregations
		};
	}
}