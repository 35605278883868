import { PlanSelect, Query as PlanQuery } from '../../plan/utils/query.utils';
import { ProgramSelect, Query as ProgramQuery } from '../../program/utils/query.utils';
import { Query as TacticQuery, TacticSelect } from '../../tactic/utils/query.utils';
import { Query as CommentsQuery } from '../../comment/utils/query.utils';

export enum FileSelect {
	BudgetPeriod = 'budgetPeriod',
	Plan = 'plan',
	Program = 'program',
	Tactic = 'tactic',
	Approvers = 'approvers',
	Reviewers = 'reviewers',
	Comments = 'comments',
	Versions = 'versions'
}

export interface FileSubSelects {
	programSelects?: ProgramSelect[];
}

export class Query {
	private static readonly FILE_QUERY_ALIAS: string = 'f';

	public static getSelects(
		alias: string = this.FILE_QUERY_ALIAS,
		targets: FileSelect[] = Object.values(FileSelect),
		asJsonSelect: boolean = false
	) {
		if (!targets) {
			targets = [];
		}
		const selects: string[] = [];
		const json: boolean = asJsonSelect;

		selects.push(`
			${json ? `'id', ${alias}.id` : `${alias}.id`}
		`);
		selects.push(`
			${json ? `'name', ${alias}.name` : `${alias}.name`}
		`);
		selects.push(`
			${json ? `'category', ${alias}.category` : `${alias}.category`}
		`);
		selects.push(`
			${json ? `'s3Path', ${alias}."s3Path"` : `${alias}."s3Path"`}
		`);
		selects.push(`
			${json ? `'mimeType', ${alias}."mimeType"` : `${alias}."mimeType"`}
		`);
		selects.push(`
			${json ? `'s3Path', ${alias}."s3Path"` : `${alias}."s3Path"`}
		`);
		selects.push(`
			${json ? `'path', ${alias}.path` : `${alias}.path`}
		`);
		selects.push(`
			${json ? `'budgetPeriodId', ${alias}."budgetPeriodId"` : `${alias}."budgetPeriodId"`}
		`);
		selects.push(`
			${json ? `'planId', ${alias}."planId"` : `${alias}."planId"`}
		`);
		selects.push(`
			${json ? `'programId', ${alias}."programId"` : `${alias}."programId"`}
		`);
		selects.push(`
			${json ? `'requiresApproval', ${alias}."requiresApproval"` : `${alias}."requiresApproval"`}
		`);
		selects.push(`
			${json ? `'approved', ${alias}."approved"` : `${alias}."approved"`}
		`);
		selects.push(`
			${json ? `'tacticId', ${alias}."tacticId"` : `${alias}."tacticId"`}
		`);
		selects.push(`
			${json ? `'created', ${alias}.created` : `${alias}.created`}
		`);
		selects.push(`
			${json ? `'updated', ${alias}.updated` : `${alias}.updated`}
		`);
		selects.push(`
			${json ? `'versions', ${alias}.versions` : `${alias}.versions`}
		`);
		selects.push(`
			${json ? `'isVersion', ${alias}."isVersion"` : `${alias}."isVersion"`}
		`);
		selects.push(`
			${json ? `'feedbackDueDate', ${alias}."feedbackDueDate"` : `${alias}."feedbackDueDate"`}
		`);
		selects.push(`
			${json ? `'reviewMessage', ${alias}."reviewMessage"` : `${alias}."reviewMessage"`}
		`);
		selects.push(`
			${(json) ? `'thumbnail', ${alias}."thumbnail"` : `${alias}."thumbnail"`}
		`);
		selects.push(`
			${(json) ? `'thumbnailS3Path', ${alias}."thumbnailS3Path"` : `${alias}."thumbnailS3Path"`}
		`);

		if (targets.includes(FileSelect.BudgetPeriod)) {
			if (json) {
				selects.push(`
					'budgetPeriod', ${alias}_budgetperiod."budgetPeriod"
				`);
			} else {
				selects.push(`
					${alias}_budgetperiod."budgetPeriod" as "budgetPeriod"
				`);
			}
		}

		if (targets.includes(FileSelect.Plan)) {
			if (json) {
				selects.push(`
					'plan', ${alias}_plan."plan"
				`);
			} else {
				selects.push(`
					${alias}_plan."plan" AS plan
				`);
			}
		}

		if (targets.includes(FileSelect.Program)) {
			if (json) {
				selects.push(`
					'program', ${alias}_program."program"
				`);
			} else {
				selects.push(`
					${alias}_program."program" AS program
				`);
			}
		}

		if (targets.includes(FileSelect.Tactic)) {
			if (json) {
				selects.push(`
					'tactic', ${alias}_tactic."tactic"
				`);
			} else {
				selects.push(`
					${alias}_tactic."tactic" AS tactic
				`);
			}
		}

		if (targets.includes(FileSelect.Approvers)) {
			if (json) {
				selects.push(`
					'approvers', ${alias}_approvers."approversArr"
				`);
			} else {
				selects.push(`
					${alias}_approvers."approversArr" AS "approvers"
				`);
			}
		}

		if (targets.includes(FileSelect.Reviewers)) {
			if (json) {
				selects.push(`
					'reviewers', ${alias}_reviewers."reviewersArr"
				`);
			} else {
				selects.push(`
					${alias}_reviewers."reviewersArr" AS "reviewers"
				`);
			}
		}

		if (targets.includes(FileSelect.Comments)) {
			if (json) {
				selects.push(`
					'comments', ${alias}_comments."commentsArr"
				`);
			} else {
				selects.push(`
					${alias}_comments."commentsArr" AS "comments"
				`);
			}
		}

		return selects.join(',');
	}

	public static getSubqueries(
		alias: string = this.FILE_QUERY_ALIAS,
		targets: FileSelect[] = Object.values(FileSelect),
		subSelects?: FileSubSelects
	) {
		if (!targets?.length) {
			return '';
		}

		const subQueries: string[] = [];

		if (targets.includes(FileSelect.BudgetPeriod)) {
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								'id', bp2.id,
								'name', bp2.name,
								'organizationId', bp2."organizationId",
								'name', bp2.name,
								'start', bp2.start,
								'end', bp2.end,
								'state', bp2.state,
								'created', bp2.created,
								'hidePlanningFundingTypes', bp2."hidePlanningFundingTypes",
								'brandStrategyPreference', bp2."brandStrategyPreference",
								'deleted', bp2.deleted
							)
						FROM
							"budgetPeriods" AS bp2
						WHERE
							bp2.id = ${alias}."budgetPeriodId"
							AND bp2.deleted != true
					) AS "budgetPeriod"
				) as ${alias}_budgetperiod
			`);
		}

		if (targets.includes(FileSelect.Plan)) {
			const planAlias = 'pl2';
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								${PlanQuery.getSelects(planAlias, [PlanSelect.Author], true)}
							)
						FROM
							"plans" AS ${planAlias}
							${PlanQuery.getSubqueries(planAlias, [PlanSelect.Author])}
						WHERE
							${planAlias}.id = ${alias}."planId"
							AND ${planAlias}.deleted != true
					) AS "plan"
				) AS ${alias}_plan
			`);
		}

		if (targets.includes(FileSelect.Program)) {
			const programAlias = 'p2';
			let programSelects = [ProgramSelect.Author];
			if (subSelects?.programSelects) {
				programSelects = subSelects.programSelects;
			}
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								${ProgramQuery.getSelects(programAlias, programSelects, true)}
							)
						FROM
							"programs" AS ${programAlias}
							${ProgramQuery.getSubqueries(programAlias, programSelects)}
						WHERE
							${programAlias}.id = ${alias}."programId"
							AND ${programAlias}.deleted != true
					) AS "program"
				) AS ${alias}_program
			`);
		}

		if (targets.includes(FileSelect.Tactic)) {
			const tacticAlias = 't2';
			subQueries.push(`
				LATERAL (
					SELECT (
						SELECT
							JSON_BUILD_OBJECT (
								${TacticQuery.getSelects(tacticAlias, [TacticSelect.Author], true)}
							)
						FROM
							"tactics" AS ${tacticAlias}
							${TacticQuery.getSubqueries(tacticAlias, [TacticSelect.Author])}
						WHERE
							${tacticAlias}.id = ${alias}."tacticId"
							AND ${tacticAlias}.deleted != true
					) AS "tactic"
				) AS ${alias}_tactic
			`);
		}

		if (targets.includes(FileSelect.Approvers)) {
			const appAlias = 'a2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							ROW_TO_JSON(u2)
						FROM
							"fileApprovers" AS ${appAlias}
						INNER JOIN
							users AS u2
							ON u2.id = ${appAlias}."usersId"
						WHERE
							${appAlias}."filesId" = ${alias}.id
					) AS "approversArr"
				) AS ${alias}_approvers
			`);
		}

		if (targets.includes(FileSelect.Reviewers)) {
			const appAlias = 're2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							ROW_TO_JSON(u2)
						FROM
							"fileReviewers" AS ${appAlias}
						INNER JOIN
							users AS u2
							ON u2.id = ${appAlias}."usersId"
						WHERE
							${appAlias}."filesId" = ${alias}.id
					) AS "reviewersArr"
				) AS ${alias}_reviewers
			`);
		}

		if (targets.includes(FileSelect.Comments)) {
			const commentsAlias = 'cmm2';
			subQueries.push(`
				LATERAL (
					SELECT ARRAY (
						SELECT
							JSON_BUILD_OBJECT (
								${CommentsQuery.getSelects(commentsAlias, undefined, true)}
							)
						FROM
							"comments" AS ${commentsAlias}
							${CommentsQuery.getSubqueries(commentsAlias)}
						WHERE
							${commentsAlias}."fileId" = ${alias}.id
						ORDER BY
							${commentsAlias}.created ASC
					) AS "commentsArr"
				) AS ${alias}_comments
			`);
		}

		if (!subQueries.length) {
			return '';
		}

		return ',\n' + subQueries.join(',');
	}
}
