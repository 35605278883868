import { EntityState } from '@datorama/akita';
import { CommentStatus } from '../../../../../../api/src/comment/comment.entity';
import { PublicFile } from '../../../../../../api/src/file/file.entity';
import { Plan } from '../../../../../../api/src/plan/plan.entity';
import { SortStrategy } from '../../table/table.model';
import { Entity } from '../entities.model';
import { FilterParameters } from '../filter/filter.model';
import { Program } from '../program/program.model';
import { Tactic } from '../tactic/tactic.model';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';

export interface FileState extends EntityState<PublicFile> {
	totalResults: number;
	limit: number;
	page: number;
	category: FileCategory;
	budgetPeriodId: PublicBudgetPeriod['id'];
	programId: Program['id'];
	tacticId: Tactic['id'];
	sort: FileSort;
	sortStrategy: SortStrategy;
	programFacets?: FilterParameters;
	planFacets?: FilterParameters;
	tacticFacets?: FilterParameters;
	tacticTypeId?: string;
}

/**
 * Parameters that can be passed to the edit file dialog to update a file.
 */
export interface EditFileDialog {
	category: string;
	data: EditFile;
	id: string;
	planId: string;
	programId?: string;
	tacticId?: string;
	tactic?: Tactic;
}

export interface EditFile {
	name: string;
	type: string;
	path: string;
}

/**
 * File entities are grouped by a category.  FileCategory items come from a static list below.
 */
export interface FileCategory extends Entity {
	id: FileCategoryValue;
	name: string;
}

/**
 * Possible sorting methods for files.
 */
export type FileSort = 'category' | 'created' | 'name';

/**
 * Possible values for file categories.
 */
export type FileCategoryValue =
	| 'audienceDefinition'
	| 'brief'
	| 'creative'
	| 'measurementRecap'
	| 'other'
	| 'strategyDocument'
	| 'contract'
	| 'invoice'
	| 'estimate'
	| 'storeList'
	| 'asset'
	| 'skuList'
	| 'considerationSet'
	| 'activationMediaPlan'
	| 'onePager'
	| 'programOverview'
	| 'sellingDeck';

/**
 * Parameters to be passed as an upload request to the API.
 */
export interface FileUploadRequest {
	name: string;
	category?: FileCategory;
	tactic?: Tactic;
	program?: Program;
	data: Blob;
}

export interface GetReviewFilesDto {
	orgId: string;
	page: number;
	limit: number;
	budgetPeriodId?: PublicBudgetPeriod['id'];
	budgetPeriodQueryType: 'plan' | 'program';
	approvedByMe?: boolean;
	needsMyApproval?: boolean;
	planId?: Plan['id'];
	programId?: Program['id'];
	tacticId?: Tactic['id'];
	brandIds?: string[];
	retailerIds?: string[];
	reviewStatus?: CommentStatus;
	category: FileCategory;
	sort: FileSort;
	sortStrategy: SortStrategy;
}

export const fileApprovalOptions = [
	{
		id: 'none',
		name: 'Set Approval Status',
		value: undefined
	},
	{
		id: 'approved',
		name: 'Approved',
		value: CommentStatus.Approved
	},
	{
		id: 'changes-requested',
		name: 'Changes Requested',
		value: CommentStatus.ChangesRequested
	}
];

/**
 * File category list of possible values.
 */
export const FileCategories: FileCategory[] = [
	{
		id: undefined,
		name: 'All'
	},
	{
		name: 'Audience Definition',
		id: 'audienceDefinition'
	},
	{
		name: 'Brief',
		id: 'brief'
	},
	{
		name: 'Strategy Document',
		id: 'strategyDocument'
	},
	{
		name: 'Creative',
		id: 'creative'
	},
	{
		name: 'Reporting Recap',
		id: 'measurementRecap'
	},
	{
		name: 'Contract',
		id: 'contract'
	},
	{
		name: 'Estimate',
		id: 'estimate'
	},
	{
		name: 'Invoice',
		id: 'invoice'
	},
	{
		name: 'Store List',
		id: 'storeList'
	},
	{
		name: 'Asset',
		id: 'asset'
	},
	{
		name: 'Other',
		id: 'other'
	},
	{
		name: 'SKU List',
		id: 'skuList'
	},
	{
		name: 'Consideration Set',
		id: 'considerationSet'
	},
	{
		name: 'Activation/Media Plan',
		id: 'activationMediaPlan'
	},
	{
		name: 'One-Pager',
		id: 'onePager'
	},
	{
		name: 'Program Overview',
		id: 'programOverview'
	},
	{
		name: 'Selling Deck',
		id: 'sellingDeck'
	},
];

/**
 * Create a new file upload request.  Default values go here.
 */
export function createFileUploadRequest(params: Partial<FileUploadRequest>) {
	return {
		name: undefined,
		category: undefined,
		tactic: undefined,
		data: undefined,
		...params
	} as FileUploadRequest;
}
